/* eslint-disable indent */
import { navigate } from 'gatsby';
import React from 'react';
import { useTranslation } from 'react-i18next';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { PersonNo, PersonNoRetina, PersonYes, PersonYesRetina } from '@/assets/images';
import { StyledButtonWithArrow } from '@/components';
import { COLORS } from '@/constants';
import { applyRetinaImage } from '@/utils/retina';

interface MessageProps {
  title: string;
  subTitle: string;
  type: 'ERROR' | 'SUCCESS' | 'NOT_FOUND';
}

interface ErrorContentProps {
  type: 'ERROR' | 'SUCCESS' | 'NOT_FOUND';
}

const Content = styled.div<ErrorContentProps>`
  > .section-container {
    background-color: ${COLORS.ERROR_PAGE_BACKGROUND};
    padding: 79px 200px 79px 138px;

    @media (max-width: 1679px) {
      padding-right: 138px;
    }

    @media (max-width: 1399px) {
      padding-top: 65px;
      padding-bottom: 65px;
    }

    @media (max-width: 1199px) {
      padding-left: 70px;
      padding-right: 70px;
    }

    @media (max-width: 991px) {
      padding: 140px 36px 40px 36px;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 991px) {
      width: 100%;
      max-width: 696px;
      margin: 0 auto;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
  }

  .content {
    max-width: 536px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-self: center;

    @media (max-width: 1399px) {
      max-width: 465px;
    }

    .content-text {
      h2 {
        @media (max-width: 991px) {
          font-weight: bold;
        }

        @media (max-width: 767px) {
          margin-bottom: 34px;
        }
      }
    }
  }

  .button-back {
    margin-top: 100px;
    flex-direction: row-reverse;
    justify-content: flex-end;

    .button {
      font-size: 14px;

      @media (max-width: 374px) {
        height: 50px;
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 13px;
        padding-right: 13px;
        font-size: 12px;
      }
    }

    @media (max-width: 1399px) {
      margin-top: 80px;
    }

    @media (max-width: 991px) {
      display: none;
      margin-top: 50px;
    }

    &.mobile {
      display: none;

      @media (max-width: 991px) {
        display: flex;
      }
    }

    .arrow-box {
      margin: 0 2px 0 0;
      transform: rotate(180deg);

      @media (max-width: 374px) {
        height: 50px;
        width: 50px;
      }
    }
  }

  .image {
    width: 440px;
    height: 440px;
    background-image: ${({ type }): string => (type === 'SUCCESS' ? `url(${PersonYes})` : `url(${PersonNo})`)};
    background-repeat: no-repeat;
    background-position-y: center;
    background-size: 100% auto;

    ${({ type }): FlattenSimpleInterpolation => {
      if (type === 'SUCCESS') {
        return applyRetinaImage(PersonYesRetina || PersonYes);
      }
      return applyRetinaImage(PersonNoRetina || PersonNo);
    }};

    @media (max-width: 1399px) {
      width: 350px;
      height: 350px;
    }

    @media (max-width: 991px) {
      margin-top: 50px;
    }

    @media (max-width: 767px) {
      max-width: 300px;
      max-height: 300px;
    }
  }

  .paragraph {
    font-size: 1rem;
    line-height: 1.5;
    color: ${COLORS.TEXT_SECONDARY};
  }
`;

const Message = ({ title, subTitle, type }: MessageProps): JSX.Element => {
  const { t } = useTranslation();

  const handleOnClick = (): void => {
    navigate('/');
  };

  return (
    <Content type={type}>
      <div className="section-container">
        <div className="container">
          <div className="content">
            <div className="content-text">
              <h2>{ title }</h2>
              <p className="paragraph">{ subTitle }</p>
            </div>
            <StyledButtonWithArrow
              className="button-back"
              color="secondary"
              direction="right"
              onClick={handleOnClick}
              title={t('common:button.back-to-website')}
            />
          </div>
          <div className="image" />
          <StyledButtonWithArrow
            className="button-back mobile"
            color="secondary"
            direction="right"
            onClick={handleOnClick}
            title={t('common:button.back-to-website')}
          />
        </div>
      </div>
    </Content>
  );
};

export default Message;
