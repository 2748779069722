import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import NavigationBar from '@/components/NavigationBar';
import ContactForm from '@/components/Sections/ContactForm';
import Footer from '@/components/Footer';
import Message from '@/components/Message';
import Layout from '@/styles';

const InfoContainer = styled.div`
  padding: 140px 0;

  @media (max-width: 991px) {
    padding: 0 0 20px 0;
  }
`;

interface InfoProps {
  type: 'ERROR' | 'SUCCESS' | 'NOT_FOUND';
}

const Info = ({ type }: InfoProps): JSX.Element => {
  const { t } = useTranslation();

  const getMessageTitle = (infoType: 'ERROR' | 'SUCCESS' | 'NOT_FOUND'): string => {
    switch (infoType) {
      case 'SUCCESS':
        return t('common:message.success.title');
      case 'NOT_FOUND':
        return t('common:message.not-found.title');
      default:
        return t('common:message.error.title');
    }
  };

  const getMessageSubTitle = (infoType: 'ERROR' | 'SUCCESS' | 'NOT_FOUND'): string => {
    switch (infoType) {
      case 'SUCCESS':
        return t('common:message.success.sub-title');
      case 'NOT_FOUND':
        return t('common:message.not-found.sub-title');
      default:
        return t('common:message.error.sub-title');
    }
  };

  return (
    <Layout>
      <NavigationBar
        darkMenuIcon
        initialValue="NONE"
      />
      <InfoContainer>
        <Message
          subTitle={getMessageSubTitle(type)}
          title={getMessageTitle(type)}
          type={type}
        />
      </InfoContainer>
      <ContactForm />
      <Footer />
    </Layout>
  );
};

export default Info;
